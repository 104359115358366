import { Injectable, inject } from '@angular/core';
import { Observable, take } from 'rxjs';

import { ApiObservableService } from '@yuno/angular/api';
import { GeoPhoto } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {
	private readonly api = inject(ApiObservableService);

	getItems(appId: string, categoryId: string): Observable<GeoPhoto[]> {
		return this.api.get<GeoPhoto[]>(`geophotos/${appId}/${categoryId}/photos`).pipe(take(1));
	}
}
